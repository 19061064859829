
import { defineComponent, ref, onMounted, computed } from "vue";
import PolicyService from "@/core/services/car/PolicyService";

import { useStore } from "vuex";
import router from "@/router";
import InvoiceService from "@/core/services/car/InvoiceService";
import { notif } from "@/store/stateless/store";
interface PolicyBodyParam {
  policy_id: any;
}
export default defineComponent({
  name: "quotes-action",
  components: {},
  props: {
    policy: Object,
    action: String,
    showcancelAction: Boolean,
    workFlow: String,
    showRefund: {
      type: Boolean,
      required: false,
      default: true,
    },
    isPolicyRefund: {
      type: Boolean,
      required: false,
      default: false
    },
    status: {
      type: Number,
      required: false,
      default: 1
    },
    isqca: {
      type: Boolean,
      required: false,
      default: false
    },
    showInitGe: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  setup(_, context) {
    const store = useStore();
    const userPermissions = computed(() => {
      return store.getters.getUserPermissions;
    });

    const viewPolicyDocument = (policy) => {
        window.open(policy.file_path,'_blank');
    }

    const viewPolicy = (id) => {
      const link = router.resolve({name: 'upload-policy-documents', params: {policyId: id}, query: {isView:'yes'}});
      window.open(link.href.toString(), '_blank');
    }

    const showAction = (data) => {
      const user = store.getters.currentUser;
      return data.includes(user.role_id);
    }

    const flagInitGE = ref(false)

    const initiateGE = async (policy) => {
      flagInitGE.value = true
      const data = {
        name: policy.customer.name,
        email: policy.customer.email,
        phone_number: policy.customer.phone_number,
        invoice_id: policy.car_lead_invoice.id,
        invoice_number: policy.car_lead_invoice.merchant_reference,
        policy_sales_agent_id: policy.policy_sales_agent_id,
        original_price: null,
        amount: 0,
        payment_type: policy.transaction_type,
        order_description: 'system',
        reference_no: 'system',
        language: 'en',
        expiry_date_time: null,
        is_amend: 1,
        amend_type: 5,
        policy_end_date: null,
        initiatedFromPolicy: true
      }
      const response = await InvoiceService.initiateGE(data);
      if(response && response.status === 200) {
        notif.simple('GE Initiated', 'success', 'GE has been initiated successfully')
        context.emit('reloadPage')
        flagInitGE.value = false
      } else {
        notif.simple('GE Error', 'success', 'There has been a problem with initiating a GE')
      }
    }

    const openDocuments = (policy_id, lead_id) => {
      // console.log(policy_id)
      PolicyService.getAllViewDocuments({policy_id, lead_id})
    }

    const viewReceipt = (invoice) => {
      window.open(invoice.invoice_doc.file_path, "_blank");
    };

    const checkPolicyAssignedStateAndQca = (policy) =>{
      const currentUser = store.getters.currentUser;
      return [14].includes(currentUser.role_id) && policy.status === 2;
    }
    return {
      openDocuments,
      flagInitGE,
      initiateGE,
      viewPolicy,
      showAction,
      viewPolicyDocument,
      userPermissions,
      viewReceipt,
      checkPolicyAssignedStateAndQca
    };
  },
});
